import React from 'react'

const OwnerDetailsContainer = () => {
  return (
    <div className='owner-details-container mr-10 sm:mr-28'>
        <div className='inors-container mb-2'>
            <h2 className='text-2xl sm:text-3xl text-black poppins font-bold leading-tight'>This Domain is owned by</h2>
            <h2 className='text-4xl sm:text-5xl text-black poppins font-bold mt-1'> INORS</h2>
            <h2 className='text-4xl sm:text-5xl text-black poppins font-bold'> Technologies.</h2>
        </div>
        <div className='interested-container'>
            <p className='text-black helvetica text-xl'>Please fill the form if you are interested in the domain.</p>
        </div>
    </div>
  )
}

export default OwnerDetailsContainer
