import './App.css';
import { ToastContainer } from 'react-toastify';
import { MainContainer } from './components/MainContainer';
// import Tilt from 'react-parallax-tilt';

function App() {
  return (
    <>
      <div>
        {/* <Tilt> */}
          <MainContainer />
        {/* </Tilt> */}
        <ToastContainer/>
      </div>
    </>
  );
}

export default App;