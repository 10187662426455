import React from 'react'
import OwnerDetailsContainer from './OwnerDetailsContainer'
import { FormContainer } from './FormContainer'

export const MainContainer = () => {
  return (
    <div className='background App h-screen w-screen flex justify-center items-center'>
        <div className="main-container h-screen w-screen sm:h-auto sm:w-auto overflow-scroll">
          <div className='content-container m-6 sm:mr-0 sm:mt-8 sm:ml-8 sm:mb-8'>
            <OwnerDetailsContainer />
            <FormContainer />
          </div>
      </div>
    </div>
  )
}

