import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { ContactForm } from './ContactForm';
export const FormContainer = () => {
  return (
    <>
      <div className='form-container mt-5'>
        <div className='form-content sm:grid sm:grid-cols-5'>
          <div className='contact-form-grid sm:col-span-4'>
            <ContactForm />
          </div>
          <div className='social-media-grid sm:col-span-1 flex justify-center sm:justify-start sm:flex-col sm:items-end my-10 sm:m-0'>
              <div className='social-media-container sm:mt-7'>
                <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><FontAwesomeIcon className='social-media-icon facebook-icon' icon={faFacebookF} /></a>
                <a href="https://www.twitter.com" target="_blank" rel="noreferrer"><FontAwesomeIcon className='social-media-icon twitter-icon' icon={faTwitter} /></a>
                <a href="https://www.instagram.com" target="_blank" rel="noreferrer"><FontAwesomeIcon className='social-media-icon instagram-icon' icon={faInstagram} /></a>
                <a href="https://www.linkedin.com" target="_blank" rel="noreferrer"><FontAwesomeIcon className='social-media-icon linkedin-icon' icon={faLinkedin} /></a>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}
// pb-10 pr-5 ml-3