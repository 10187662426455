import { useRef, useState } from "react"
import * as Yup from 'yup'
import "yup-phone-lite";
import emailjs from '@emailjs/browser';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContactForm = () => {
    const domain = window.location.host; //Current Website Name

    const initialFormState = {
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
      domainName: domain,
  }

    const [formData, setFormData] = useState(initialFormState)

    const [formErrors, setFormErrors] = useState({});
    const [sendEmailFailed, setSendEmailFailed] = useState(false);
    const form = useRef()
    
    const validationSchema = Yup.object({
        fullName: Yup.string().required("Full Name is required"),
        email: Yup.string().email("Invalid Email Id").required("Email is required"), //matches(/^[a-zA-Z0-9_.-]+@[^@\s,.]*[.\w*]*/, "Invalid Email Id"),
        phoneNumber: Yup.string("Invalid Phone Number").required("Phone Number is required"),
        // string().phone(null, true, "Invalid phone number").required("Phone Number is required")
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
    
        setFormData({
          ...formData,
          [name]: value,
        });
      };

        //function to send toast message
      const successfulEmailNotification = () => {
        toast.success('Submitted Successfully', {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
      }

      const sendEmail = () => {

        emailjs
          .sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, form.current, {
            publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
          })
          .then(
            () => {
              setFormData(initialFormState)
              successfulEmailNotification()
              setSendEmailFailed(false)
            },
            (error) => {
              setSendEmailFailed(true);
            },
          );
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await validationSchema.validate(formData, {abortEarly: false});
            setFormErrors({});
            sendEmail()
          } catch (error) {
            const newErrors = {};
      
            error.inner.forEach((err) => {
              newErrors[err.path] = err.message;
            });
      
            setFormErrors(newErrors);
          }
        }

    return(
      <>
            <form ref={form} className='flex flex-col justify-evenly inline' noValidate onSubmit={handleSubmit} >
                <input 
                    type="hidden" 
                    name="domainName"
                    value={formData.domainName}
                    />
                <input 
                    name="fullName"
                    type="text" 
                    placeholder='Full Name' 
                    className='input-text'
                    onChange={handleChange}
                    value={formData.fullName}
                    />
                {formErrors.fullName ? <div className="text-red-500">{formErrors.fullName}</div> : ''}
                <input 
                    name="email"
                    type="email" 
                    formNoValidate={true}
                    placeholder='Email' 
                    className='input-text'
                    value={formData.email}
                    onChange={handleChange}
                    />
                {formErrors.email && <div className="text-red-500">{formErrors.email}</div>}
                <input 
                    name="phoneNumber"
                    type="number" 
                    placeholder='Phone Number' 
                    className='input-text'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    />
                  {formErrors.phoneNumber && <div className="text-red-500">{formErrors.phoneNumber}</div>}
                  <textarea
                    rows={2}
                    name="message"
                    type="text" 
                    placeholder='Message' 
                    className='input-text'
                    value={formData.message}
                    onChange={handleChange}
                    />
                <input type="Submit" value={"Send"} className='submit-button w-36 cursor-pointer tracking-wider text-white font-extrabold text-lg font-poppins rounded px-5 py-1 mt-6 sm:my-5 bg-black'/>
                { sendEmailFailed && <p className='font-nunito mt-5 text-red-600'>Unable to send email. Please send us a mail at hello@inorstech.com</p>}
            </form>
          </>
    )
}